@charset "utf-8";
/*
01) PORTFOLIO BASE
02) PORTFOLIO MOBILE MENU
03) PORTFOLIO TOPBAR
04) PORTFOLIO HERO
05) PORTFOLIO ABOUT
06) PORTFOLIO PORTFOLIO
07) PORTFOLIO SKILLS
08) PORTFOLIO VIDEO
09) PORTFOLIO PARTNERS
10) PORTFOLIO TESTIMONIALS
11) PORTFOLIO NEWS
12) PORTFOLIO TALK
13) PORTFOLIO COPYRIGHT
14) PORTFOLIO MODALBOX CONTACT
15) PORTFOLIO CURSOR
16) PORTFOLIO MEDIA QUERIES (FOR SMALL DEVIVES)


/*---------------------------------------------------*/
/*	01) PORTFOLIO BASE
/*---------------------------------------------------*/

html {
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
}
body {
  font-family: "Roboto";
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
  background-color: #fff;
  color: #767676;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 24px;
}

svg {
  fill: currentcolor;
  width: 15px;
  height: 15px;
}
img.svg {
  width: 15px;
  height: 15px;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #6f6f6f;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #6f6f6f;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #6f6f6f;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6f6f6f;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6f6f6f;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #6f6f6f;
}

.container {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.4;
  font-family: "Poppins";
  color: #000;
}

h1 {
  font-size: 45px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

.portfolio_all_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
}
.portfolio_all_wrap,
.portfolio_all_wrap * {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.portfolio_section {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: flex;
}
#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#preloader:after {
  left: auto;
  right: 0;
}
#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}
.loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  -o-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}
.loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #999999;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  -o-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  animation-delay: 2000ms;
}

@keyframes lineheight {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(200%);
    -ms-transform: translateY(200%);
    -o-transform: translateY(200%);
    transform: translateY(200%);
  }
}

/*hiding all*/
.preloaded .loader_line:after {
  opacity: 0;
}
.preloaded .loader_line {
  opacity: 0;
  height: 100% !important;
}
.preloaded:before,
.preloaded:after {
  -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  -o-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  animation: preloadedzero 300ms ease-in-out 500ms forwards;
}
@keyframes preloadedzero {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

/*---------------------------------------------------*/
/*	02) PORTFOLIO MOBILE MENU
/*---------------------------------------------------*/

.portfolio_mobile_menu {
  width: 100%;
  height: auto;
  position: relative;
  top: 0px;
  left: 0px;
  display: none;
  z-index: 10;

  .white-fill-bg.btn_sm {
    margin-top: 20px;
  }
}
.portfolio_mobile_menu .topbar_inner {
  width: 100%;
  height: auto;
  float: left;
  clear: both;
  background-color: #fff;
  padding: 20px 0px;
  box-shadow: 0 10px 40px 0 rgba(62, 68, 125, 0.1);
}
.portfolio_mobile_menu .topbar_in {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.my_trigger .hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.my_trigger .hamburger-box {
  width: 30px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 4px;
}
.my_trigger .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.my_trigger .hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #333333;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.my_trigger .hamburger-inner::before,
.my_trigger .hamburger-inner::after {
  content: "";
  display: block;
}
.my_trigger .hamburger-inner::before {
  top: -8px;
}
.my_trigger .hamburger-inner::after {
  bottom: -10px;
}
.my_trigger .hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.my_trigger .hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
}
.my_trigger .hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.my_trigger .hamburger {
  padding: 0px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.my_trigger .hamburger .hamburger-inner::before,
.my_trigger .hamburger .hamburger-inner::after,
.my_trigger .hamburger .hamburger-inner {
  background-color: #000;
  width: 26px;
  transition: all 0.3s ease;
}
.portfolio_mobile_menu .dropdown {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform: translateX(-100%);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
}
.portfolio_mobile_menu .dropdown {
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
.portfolio_mobile_menu .dropdown .dropdown_inner {
  width: 100%;
  height: 100vh;
  padding: 25px 0px;
}
.portfolio_mobile_menu .dropdown .dropdown_inner > ul {
  margin: 0px;
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -50px;
}
.portfolio_mobile_menu .dropdown .dropdown_inner > ul li {
  margin: 0px;
  float: left;
  width: 100%;
}
.portfolio_mobile_menu .dropdown .dropdown_inner > ul li a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  padding: 5px 0px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  @media only screen and (min-width: 768px) and (max-width: 1040px) {
    padding: 12px 0px;
    font-size: 25px;
    line-height: 42px;
  }
}
.portfolio_mobile_menu .logo img {
  max-width: 100px;
}
.close_menu {
  position: absolute;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  right: 40px;
  top: 15px;
  z-index: 5;
  &::after {
    content: "";
    position: absolute;
    right: -22px;
    bottom: 12px;
    background-color: #fff;
    width: 15px;
    height: 2px;
  }
}
/*---------------------------------------------------*/
/*	03) PORTFOLIO TOPBAR
/*---------------------------------------------------*/

.portfolio_topbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  transition: all 0.3s ease;
}
.portfolio_topbar .topbar_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 50px;
  transition: all 0.3s ease;
}
.portfolio_topbar .topbar_inner.opened {
  background-color: #fff;
  padding: 20px 50px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
}
.portfolio_topbar .topbar_inner .logo a {
  display: inline-block;
}

.portfolio_topbar .menu ul {
  margin: 0px;
  list-style-type: none;
}
.portfolio_topbar .menu ul li {
  margin: 0px;
  display: inline-block;
}
.portfolio_topbar .menu ul li {
  margin: 0px 25px 0px 0px;
  display: inline-block;
}
.portfolio_topbar .menu ul li:last-child {
  margin-right: 0px;
}
.portfolio_topbar .menu ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-family: "Poppins";

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio_topbar .menu ul li a {
  color: #222;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    background: currentcolor;
    height: 2px;
    transition: 0.3s;
  }
}
.portfolio_topbar .menu ul li.current a {
  &::before {
    width: 100%;
  }
}

.social.social-default {
  display: inline-flex;
  list-style: none;
  li {
    display: flex;
    position: relative;
    z-index: 4;
    &:not(:last-child) {
      margin-right: 25px;
    }
    img.svg {
      width: 20px;
      height: 20px;
      filter: invert(1);
    }
  }
}
/*---------------------------------------------------*/
/*	04) PORTFOLIO HERO
/*---------------------------------------------------*/
.portfolio_hero {
  width: 100%;
  height: 100vh;
  float: left;
  clear: both;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1040px) {
    height: 60vh;
  }
  @media screen and (max-width: 767px) {
    height: auto;
    padding: 240px 0;
  }
  .container {
    position: static;
  }
}
.slider-four {
  .portfolio_hero {
    @media screen and (max-width: 767px) {
      padding: 270px 0;
    }
  }
}
.portfolio_hero .background {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 65%;
}
.portfolio_hero .image {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.portfolio_hero[data-style="one"] .background {
  clip-path: polygon(0% 100%, 21% 0%, 100% 0%, 100% 100%);
}
.portfolio_hero[data-style="two"] .background {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  // clip-path: polygon(0% 100%, 21% 0%, 100% 0%, 100% 100%);
}
.portfolio_hero[data-style="three"] .background {
  clip-path: polygon(0% 100%, 40% 0%, 100% 0%, 100% 100%);
  // clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  // clip-path: ellipse(70% 80% at 70% 50%);
}
.portfolio_hero .container {
  height: 100%;
}

.portfolio_hero .content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 20px;
}
.portfolio_hero .name_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 0;
}
.portfolio_hero .job_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_hero .content h3 {
  position: relative;
  font-size: 72px;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-weight: 900;
  color: #000;
  line-height: 1.2;
  display: inline-block;
  overflow: hidden;
}
.portfolio_hero .content h3 span {
  color: transparent;
  -webkit-text-stroke: 1px #000;
  padding-left: 2px;
  @media screen and (max-width: 767px) {
    -webkit-text-stroke: 1px #fff;
  }
}
.portfolio_hero .content .job {
  font-weight: 500;
  font-size: 17px;
  font-family: "Roboto";
  float: left;
  position: relative;
  overflow: hidden;
  color: #555;
}
.overlay_effect {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
}
.overlay_effect:after {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #fff;
  z-index: 1;
}
.overlay_effect:before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
body.loaded .overlay_effect:before {
  z-index: 2;
  background-color: #000;
  animation: anim 2s cubic-bezier(0.77, 0, 0.175, 1) both;
}

body.loaded .overlay_effect:after {
  animation: anim2 2s cubic-bezier(0.77, 0, 0.175, 1) both;
}

@keyframes anim {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes anim2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.portfolio_down {
  position: absolute;
  z-index: 7;
  bottom: 50px;
  display: none;
}
.portfolio_down.loaded {
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.portfolio_down[data-position="left"] {
  left: 0px;
}
.portfolio_down[data-position="right"] {
  right: 0px;
}
.portfolio_down[data-position="center"] {
  left: 50%;
  transform: translateX(-50%);
}
.portfolio_down .line_wrapper {
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0px auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  visibility: visible;

  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.portfolio_down .line_wrapper {
  width: 1px;
  height: 50px;
  background: none;
  display: block;
}
.portfolio_down .line_wrapper:before {
  content: "";
  background-color: #000;
  width: 1px;
  height: 50%;
  display: block;
  top: 0;
  animation: scroll_down 2s ease-in-out infinite;
  @media screen and (max-width: 767px) {
    background-color: #fff;
  }
}
@keyframes scroll_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
    height: 50%;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.portfolio_down[data-skin="light"] .line_wrapper:before {
  background-color: #fff;
}

/*---------------------------------------------------*/
/*	05) PORTFOLIO ABOUT
/*---------------------------------------------------*/
.portfolio_about {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px;
  background-color: #f9f9f9;
  @media screen and (max-width: 992px) {
    padding: 60px 0px;
  }
}
.portfolio_about .about_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
}
.portfolio_about .left {
  padding-top: 2rem;
  margin-bottom: auto;
  width: 50%;
  padding-right: 30px;
  @media screen and (max-width: 992px) {
    padding-top: 0;
  }
}
.portfolio_about .left .image {
  position: relative;
}
.portfolio_about .left .image img {
  min-width: 100%;
  opacity: 0;
}
.portfolio_about .left .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 767px) {
    box-shadow: none;
  }
}
.portfolio_about .experience span {
  display: block;
}
.portfolio_about .experience .year {
  font-size: 80px;
  font-weight: 800;
  font-family: "Poppins";
  color: #fff;
  margin-bottom: 3px;
  line-height: 1;
  font-style: italic;
}
.portfolio_about .experience .text {
  color: #fff;
  font-style: italic;
  font-family: "Poppins";
  opacity: 0.7;
}
.portfolio_about .right {
  width: 50%;
  padding-left: 30px;
}
.portfolio_title {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_title h3 {
  font-weight: 800;
  font-family: "Poppins";
}
.portfolio_title span {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  color: #333;
  font-family: "Poppins";
  letter-spacing: 0px;
  margin-bottom: 15px;
}
.portfolio_about .right .text {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 40px;
  padding-top: 27px;
}
.portfolio_about .left .text {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 40px;
  padding-top: 27px;
}
.portfolio_about_buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.portfolio_projects_button {
  .portfolio_button {
    width: fit-content;
    height: auto;
    clear: both;
    float: none;
    margin: auto;
  }
}
.portfolio_button {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_button a {
  text-decoration: none;
  color: #fff;
  background-color: #000;
  display: inline-block;
  padding: 13px 48px;
  border: 2px solid #000;
  font-family: "Poppins";
  transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
  @media screen and (max-width: 992px) {
    padding: 8px 25px;
  }
}
.portfolio_button a:hover {
  color: #000;
  background-color: transparent;
  transform: translateY(-3px);
}

/*---------------------------------------------------*/
/*	06) PORTFOLIO PORTFOLIO
/*---------------------------------------------------*/

.portfolio_title .title_flex {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.portfolio_portfolio .portfolio_filter ul {
  margin: 0px;
  list-style-type: none;
}

.portfolio_portfolio .portfolio_filter {
  ul.react-tabs__tab-list {
    li {
      text-decoration: none;
      color: #000;
      font-family: "Poppins";
      font-weight: 500;
      cursor: pointer;
      position: relative;
      padding: 0 10px;
      display: inline-block;
      transition: 0.3s ease-in;
      &:not(:last-child) {
        margin-right: 15px;
        @media screen and (max-width: 767px) {
          margin-bottom: 5px;
          margin-right: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 13px;
      }

      &.react-tabs__tab--selected {
        background-color: #000;
        color: #fff;
      }
    }
  }
}
.portfolio_portfolio {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #fff;
  // background-color: #f9f9f9;
  padding: 100px 0px 100px 0px;
  @media screen and (max-width: 992px) {
    padding: 60px 0px 40px;
  }
}
.portfolio_portfolio .portfolio_list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 50px;
}
.portfolio_portfolio .portfolio_list ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.portfolio_portfolio .portfolio_list ul li {
  margin: 0px 0px 50px 0px;
  width: 33.3333%;
  float: left;
  padding-left: 50px;
}
.portfolio_portfolio .portfolio_list ul li .inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  overflow: hidden;
  &:hover {
    img {
      transform: scale(1.1);
    }

    .mobile_title {
      transform: translateY(0);
    }
  }
}
.portfolio_portfolio .portfolio_list .image {
  position: relative;
}

.portfolio_portfolio .portfolio_list .inner img {
  min-width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 1s cubic-bezier(0, 0, 0.2, 1);
}
ul.react-tabs__tab-list {
  position: absolute;
  right: 0;
  top: 65px;
  z-index: 2;
  @media screen and (max-width: 766px) {
    position: relative;
    top: 15px;
  }
}
.portfolio_portfolio .portfolio_list .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  clip-path: inset(0 0 0 0);

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio_portfolio .portfolio_list ul li .inner:hover .main {
  clip-path: inset(2% 2% 2% 2%);
}
.portfolio_portfolio_titles {
  white-space: nowrap;
  background: #ffffff;
  font-size: 18px;
  padding: 5px 15px;
  font-family: "Poppins";
  font-weight: 600;
  color: #000;
  position: fixed;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
}
.portfolio_portfolio_titles.visible {
  opacity: 1;
  visibility: visible;
}
.portfolio_portfolio_titles .work__cat {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  margin-top: -10px;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  padding: 5px 15px;
}
.entry {
  position: relative;
}
.portfolio_portfolio .mobile_title {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background: rgba(255, 255, 255, 0.85);
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(100%);
  cursor: default;
  transition: transform 0.5s;
  // pointer-events: none;
}
.portfolio_portfolio .mobile_title h3 {
  font-size: 18px;
  font-weight: 600;
}
.portfolio_portfolio .mobile_title span {
  font-family: "Poppins";
  font-weight: 500;
  color: #000;
}
.portfolio_portfolio .mobile_title a {
  font-family: "Poppins";
  font-weight: 600;
  color: #111;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    height: 20px;
    width: 20px;
    margin-left: 10px;
  }
  &:hover {
    font-size: 17px;
    color: #000;
    svg {
      height: 22px;
      width: 22px;
      margin-left: 10px;
    }
  }
}

/*---------------------------------------------------*/
/*	07) PORTFOLIO SKILLS
/*---------------------------------------------------*/

.portfolio_skills {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 150px 0;
  @media screen and (max-width: 992px) {
    padding: 60px 0;
  }
}
.portfolio_skills .skills_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
}
.portfolio_skills .left {
  width: 50%;
  padding-right: 50px;
}
.portfolio_skills .text {
  padding-top: 27px;
  float: left;
}
.tokyo_progress {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.progress_inner {
  width: 100%;
  margin-bottom: 34px;
}
.progress_inner:last-child {
  margin-bottom: 0px;
}
.progress_inner > span {
  margin: 0px 0px 7px 0px;
  width: 100%;
  display: block;
  text-align: left;
  font-family: "Poppins";
  font-weight: 500;
  color: #333;
}
.progress_inner span.number {
  float: right;
}
.progress_inner .background {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 4px;
}
.progress_inner .background .bar_in {
  height: 100%;
  background: #000;
  width: 0px;
  overflow: hidden;
}
.progress_inner .background .bar {
  width: 100%;
  height: 100%;
}
.progress_inner .background .bar.open {
  -webkit-animation: wow 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /* Safari 4+ */
  -moz-animation: wow 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /* Fx 5+ */
  animation: wow 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /* IE 10+ */
  width: 100%;
}

@-webkit-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.portfolio_skills .right {
  width: 50%;
  padding-left: 50px;
}

/*---------------------------------------------------*/
/*	08) PORTFOLIO VIDEO
/*---------------------------------------------------*/

.portfolio_business_video {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_business_video {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
}
.portfolio_business_video .bg {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.portfolio_business_video .bg .image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.portfolio_business_video .bg .overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.portfolio_business_video .content {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  margin: 0px auto;
  z-index: 2;
  text-align: center;
  padding: 150px 0px;
  @media screen and (max-width: 992px) {
    padding: 75px 15px;
  }
}
.portfolio_business_video .rounded {
  width: 100px;
  height: 100px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  background-color: #fff;
  margin-bottom: 45px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  @media screen and (max-width: 992px) {
    width: 75px;
    height: 75px;
    margin-bottom: 40px;
  }

  &:hover {
    transform: translateX(-50%) scale3d(1.15, 1.15, 1.15);
  }
}
.portfolio_business_video .rounded:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 0 13px 26px;
  border-color: transparent transparent transparent #000;
  top: 50%;
  left: 50%;
  margin-left: 4px;
  transform: translate(-50%, -50%);
}
.portfolio_business_video .text {
  color: #fff;
  font-family: "Poppins";
  font-size: 30px;
  max-width: 600px;
  margin: 0px auto;
  margin-bottom: 50px;
  font-weight: 800;
}
.portfolio_video_button {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_video_button a,
.portfolio_video_button button {
  text-decoration: none;
  color: #fff;
  background-color: transparent;
  display: inline-block;
  padding: 16px 50px;
  border: 2px solid #fff;
  font-family: "Poppins";
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
}
.portfolio_video_button button {
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 15px;
    padding: 10px 25px;
  }
}
.portfolio_video_button a:hover,
.portfolio_video_button button:hover {
  color: #000;
  background-color: #fff;
  transform: translateY(-3px);
}

/*---------------------------------------------------*/
/*	09) PORTFOLIO PARTNERS
/*---------------------------------------------------*/

.portfolio_partners {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_partners .partners_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 90px 0px 80px;
  @media screen and (max-width: 992px) {
    padding: 35px 0px 25px;
  }
}
.portfolio_partners .partners_inner ul {
  margin: 0px;
  list-style-type: none;
}
.portfolio_partners .partners_inner ul li {
  margin: 0px;
  opacity: 0.5;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio_partners .partners_inner ul li {
  max-width: 200px;
  max-height: 200px;
}
.portfolio_partners .partners_inner ul li:hover {
  opacity: 1;
}

/*---------------------------------------------------*/
/*	10) PORTFOLIO TESTIMONIALS
/*---------------------------------------------------*/

.portfolio_testimonials {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #f9f9f9;
  padding: 150px 0px;
  @media screen and (max-width: 992px) {
    padding: 60px 0px;
  }
}
.portfolio_testimonials .testimonials_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  justify-content: space-between;
}
.portfolio_testimonials .left {
  width: 50%;
  padding-right: 50px;
}
.portfolio_testimonials .right {
  width: 50%;
  padding-left: 50px;
}
.portfolio_testimonials .right ul {
  margin: 0px;
  list-style-type: none;
}
.portfolio_testimonials .right ul li {
  margin: 0px;
}
.portfolio_testimonials .right .texts p {
  margin-bottom: 40px;
}
.portfolio_testimonials .right .author {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
}
.portfolio_testimonials .right .author .image {
  width: 65px;
  height: 65px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}
.portfolio_testimonials .right .author .main {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  opacity: 0;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

  transform: scale(1.5) translateZ(0);
}
.portfolio_testimonials
  .right
  .slick-slide.slick-active.slick-current
  .author
  .main {
  opacity: 1;
  transition: all 1s 0.3s ease;
  transform: scale(1) translateZ(0);
}
.portfolio_testimonials .right .short {
  padding-left: 25px;
}
.portfolio_testimonials .right .short h3 {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
}
.portfolio_testimonials .right .short .author {
  font-family: "Montserrat";
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px;
  margin-bottom: 5px;
}
.portfolio_testimonials .right .short .job {
  font-weight: 400;
  display: block;
  font-size: 15px;
}
.portfolio_testimonials .right .short .author span,
.portfolio_testimonials .right .short .job span {
  position: relative;
  display: block;
  padding: 2px 0px;
  transform: translateY(102%);

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.portfolio_testimonials
  .right
  .slick-slide.slick-active.slick-current
  .short
  .author
  span {
  transform: translateY(0px);
  transition-delay: 0.3s;
}
.portfolio_testimonials
  .right
  .slick-slide.slick-active.slick-current
  .short
  .job
  span {
  transform: translateY(0px);
  transition-delay: 0.6s;
}

/*---------------------------------------------------*/
/*	11) PORTFOLIO NEWS
/*---------------------------------------------------*/

.portfolio_news {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 150px 0px 90px 0px;
  @media screen and (max-width: 992px) {
    padding: 60px 0px 10px;
  }
}
.portfolio_news .news_list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 90px;
  @media screen and (max-width: 992px) {
    padding-top: 35px;
  }
}
.portfolio_news .news_list ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.portfolio_news .news_list ul li {
  margin: 0px 0px 50px 0px;
  float: left;
  width: 33.3333%;
  padding-left: 50px;
}
.portfolio_news .news_list ul li .list_inner {
  width: 100%;
  height: 100%;
  clear: both;
  float: left;
  position: relative;
}
.portfolio_news .news_list ul li .image {
  position: relative;
  overflow: hidden;
}
.portfolio_news .news_list ul li .image img {
  min-width: 100%;
  opacity: 0;
}
.portfolio_news .news_list ul li .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(1) translateZ(0);
  transition: all 1s cubic-bezier(0, 0, 0.1, 1);
  cursor: pointer;
}
.portfolio_news .news_list ul li .list_inner:hover .main {
  transform: scale(1.1) rotate(3deg);
}
.portfolio_news .news_list ul li .details {
  width: 100%;
  float: left;
  padding-right: 15px;
  padding-top: 32px;
}
.portfolio_news .news_list ul li .details .title {
  margin-bottom: 10px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.portfolio_news .news_list ul li .details .title:hover {
  color: #000;
}
.portfolio_news .news_list ul li .details .date {
  text-transform: uppercase;
  font-family: "Poppins";
  font-size: 12px;
  color: #767676;
  font-style: italic;
}
.portfolio_news .news_list ul li .details .date a {
  text-decoration: none;
  color: #767676;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio_news .news_list ul li .details .date a:hover {
  color: #000;
}
.portfolio_news .news_list ul li .details .date span {
  position: relative;
  margin-left: 11px;
}
.portfolio_news .news_list ul li .details .date span:before {
  position: absolute;
  content: "";
  margin-top: 1px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 7px;
}
.portfolio_news .description {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -11;
  display: none;
}
body.modal {
  overflow: hidden;
}

.portfolio_modalbox_news .close {
  position: fixed;
  left: 100%;
  top: 0px;
  margin-left: 40px;
  z-index: 20;
}
.portfolio_modalbox_news .close a {
  text-decoration: none;
  color: #fff;
}
.portfolio_modalbox_news .close .svg {
  width: 50px;
  height: 50px;
}
.portfolio_modalbox_news .description_wrap {
  position: relative;
  width: 100%;
  float: left;
  padding: 50px;
}
.portfolio_modalbox_news .details {
  width: 100%;
  float: left;
  margin-bottom: 27px;
}
.portfolio_modalbox_news .description {
  width: 100%;
  float: left;
}
.portfolio_modalbox_news .description p {
  margin-bottom: 15px;
}
.portfolio_modalbox_news .description blockquote {
  font-style: italic;
  color: #000;
  border-left: 2px solid #000;
  padding-left: 20px;
  margin-bottom: 15px;
}
.portfolio_modalbox_news .description_wrap .image {
  position: relative;
  height: 450px;
  z-index: -1;
  margin-bottom: 36px;
  @media (max-width: 767px) {
    height: auto;
  }
}
.portfolio_modalbox_news .description_wrap .image img {
  min-width: 100%;
}
.portfolio_modalbox_news .description_wrap .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.portfolio_modalbox_news .details .title {
  font-weight: 600;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
.portfolio_modalbox_news .date {
  text-transform: uppercase;
  font-family: "Poppins";
  font-size: 12px;
  color: #767676;
  font-style: italic;
}
.portfolio_modalbox_news .date a {
  text-decoration: none;
  color: #767676;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio_modalbox_news .date a:hover {
  color: #e54b4b;
}
.portfolio_modalbox_news .date span {
  position: relative;
  margin-left: 11px;
}
.portfolio_modalbox_news .date span:before {
  position: absolute;
  content: "";
  margin-top: 0px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 7px;
}
.portfolio_modalbox_news .share {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  padding-top: 14px;
}
.portfolio_modalbox_news .share span {
  font-family: "Poppins";
  color: #000;
  font-weight: 600;
  display: inline-block;
  padding-right: 20px;
}
.portfolio_modalbox_news .share ul {
  margin: 0px;
  list-style-type: none;
}
.portfolio_modalbox_news .share ul li {
  margin: 0px 15px 0px 0px;
  display: inline-block;
}
.portfolio_modalbox_news .share ul li:last-child {
  margin-right: 0px;
}
.portfolio_modalbox_news .share ul li a {
  text-decoration: none;
  color: #000;
  img {
    &:hover {
      filter: opacity(0.8);
    }
  }
}
.portfolio_full_link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 4;
}

/*---------------------------------------------------*/
/*	12) PORTFOLIO TALK
/*---------------------------------------------------*/

.portfolio_talk {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 220px 0px 170px 0px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 150px 0;
  }
}
.portfolio_talk .talk_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.portfolio_talk .text {
  margin-bottom: 35px;
  text-align: center;
}
.portfolio_talk .text h3 {
  color: #fff;
  font-weight: 800;
  font-size: 72px;
}
.white-fill-bg {
  text-decoration: none;
  color: #000;
  background-color: #fff;
  display: inline-block;
  padding: 15px 48px;
  border: 2px solid #fff;
  font-family: "Poppins";
  transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
  cursor: pointer;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    padding: 10px 25px;
    font-size: 15px;
  }

  &.fill-black {
    background: #000;
    color: #fff;
    border-color: #000;
  }
  &.btn-outline {
    background: transparent;
    color: #fff;
  }
  &.btn_sm {
    margin-top: 0;
    padding: 5px 25px !important;
    font-size: 15px !important;
    text-transform: uppercase;
    border-color: #c8c8c8 !important;
    &:hover {
      border-color: #fff;
      transform: translateY(-3px);
    }
  }
}
.white-fill-bg {
  &:hover {
    color: #fff;
    background-color: transparent;
    transform: translateY(-3px);
  }
  &.fill-black {
    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
  &.btn-outline {
    &:hover {
      background: #fff !important;
      color: #000 !important;
      border-color: #fff !important;
    }
  }
}
.portfolio_talk .background {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.portfolio_talk .overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

.portfolio_talk .shape {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -13px;
  z-index: 2;
  transform: rotate(180deg);
}
.portfolio_talk .shape .svg {
  width: 100%;
  height: 100%;
}

/*---------------------------------------------------*/
/*	13) PORTFOLIO COPYRIGHT
/*---------------------------------------------------*/

.portfolio_copyright {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #000;
  padding: 50px 0px;
}
.portfolio_copyright .inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: center;
}
.portfolio_copyright p {
  font-family: "Poppins";
  color: #fff;
}
.portfolio_copyright a {
  text-decoration: none;
  color: #fff;
}

/*---------------------------------------------------*/
/*	14) PORTFOLIO MODALBOX CONTACT
/*---------------------------------------------------*/

.portfolio_mobalbox_contact .description_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  padding: 50px;
}
.portfolio_mobalbox_contact .title h3 {
  font-weight: 800;
}
.portfolio_mobalbox_contact .wrapper {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  padding-top: 31px;
  margin-bottom: 50px;
}
.portfolio_mobalbox_contact .desc {
  max-width: 50%;
  float: left;
  padding-top: 95px;
}
.portfolio_mobalbox_contact .left {
  width: 50%;
  padding-right: 25px;
}
.portfolio_mobalbox_contact .fields {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_mobalbox_contact .fields .first {
  width: 100%;
  float: left;
}
.portfolio_mobalbox_contact .fields ul {
  margin: 0px;
  list-style-type: none;
}
.portfolio_mobalbox_contact .fields ul li {
  width: 100%;
  margin: 0px 0px 30px 0px;
  float: left;
}
.portfolio_mobalbox_contact .fields ul li input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.portfolio_mobalbox_contact .fields ul li input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.portfolio_mobalbox_contact .fields textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 120px;
  resize: none;
  background-color: #fff;
}
.portfolio_mobalbox_contact .fields .last textarea:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.portfolio_mobalbox_contact .empty_notice {
  color: #f52225;
  margin-bottom: 7px;
  display: none;
  text-align: left;
  font-weight: 500;
}
.contact_form ul li span {
  color: #f52225;
  text-align: left;
  font-weight: 400;
}
.portfolio_mobalbox_contact .returnmessage {
  color: #3a00ff;
  margin-bottom: 7px;
  text-align: left;
  font-weight: 500;
}
.portfolio_mobalbox_contact .right {
  width: 50%;
  padding-left: 25px;
  position: relative;
  // top: 6px;
}
.portfolio_mobalbox_contact .map_wrap {
  height: auto;
  .map > div {
    height: 360px !important;
    width: 100% !important;
    @media screen and (max-width: 767px) {
      height: 290px !important;
    }
  }
}
.portfolio_mobalbox_contact .short_info {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-bottom: 20px;
}
.portfolio_mobalbox_contact .short_info > ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.portfolio_mobalbox_contact .short_info > ul > li {
  margin: 0px 0px 30px 0px;
  float: left;
  width: 50%;
  padding-left: 35px;
  text-align: center;
}
.portfolio_mobalbox_contact .short_info > ul > li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  background-color: #000;
  padding: 50px 15px;
}
.portfolio_mobalbox_contact .short_info > ul > li p {
  font-family: "Poppins";
  font-weight: 500;
  color: #fff;
}
.portfolio_mobalbox_contact .short_info > ul > li a {
  text-decoration: none;
  color: #fff;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio_mobalbox_contact .short_info > ul > li a:hover {
  color: #fff;
}
.portfolio_mobalbox_contact .short_info > ul > li .svg {
  width: 30px;
  height: 30px;
  margin-bottom: 12px;
  filter: invert(1);
}
.portfolio_mobalbox_contact .social {
  margin: 0px;
  list-style-type: none;
  position: relative;
  top: 11px;
}
.portfolio_mobalbox_contact .social li {
  margin: 0px 15px 0px 0px;
  display: inline-block;
  width: auto !important;
}
.portfolio_mobalbox_contact .social li:last-child {
  margin-right: 0px;
}
.portfolio_mobalbox_contact .social li a {
  text-decoration: none;
  color: #fff;
}
.portfolio_mobalbox_contact .social li .svg {
  width: 15px !important;
  height: 15px !important;
}
.portfolio_mobalbox_contact .share > span {
  display: none;
}
.portfolio_mobile_menu .white-fill-bg.btn_sm {
  @media screen and (min-width: 768px) and (max-width: 1040px) {
    padding: 5px 40px !important;
  }
}
.portfolio_mobile_menu .dropdown {
  &.active {
    ul {
      li {
        &:nth-child(1) {
          animation: 600ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(2) {
          animation: 700ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(3) {
          animation: 800ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(4) {
          animation: 900ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(5) {
          animation: 1000ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(6) {
          animation: 1100ms ease-in-out 0s normal none 1 running customThree;
        }
      }
    }
    .close_menu {
      animation: 1.2s ease-in-out 0s normal none 1 running customThree;
    }
  }
}
.social-default {
  opacity: 0;
}
.loaded {
  .social-default {
    opacity: 1;
    animation: 4s ease-in-out 0s normal none 1 running customFour;
  }
}
.loaded .home-three {
  .overlay_effect:after {
    background-color: #f9f9f9;
  }
}
.loaded .home-four {
  .overlay_effect:after {
    background-color: #003a55;
  }
  .overlay_effect:before {
    background-color: #fff;
  }
}
@keyframes customFour {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.customFour {
  -webkit-animation-name: customThree;
  animation-name: customThree;
}
@keyframes customThree {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.customThree {
  -webkit-animation-name: customThree;
  animation-name: customThree;
}
/*---------------------------------------------------*/
/*	15) PORTFOLIO CURSOR
/*---------------------------------------------------*/

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 100;
  background-color: #000;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out,
    margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: #000;
  opacity: 0.3;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 100;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}
.portfolio_all_wrap[data-magic-cursor="hide"] .mouse-cursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

/*---------------------------------------------------*/
/*  PORTFOLIO SETTINGS
/*---------------------------------------------------*/

.portfolio_settings {
  position: fixed;
  z-index: 200;
  top: 15%;
  right: -200px;
  width: 200px;
  background-color: rgba(0, 0, 0, 1);
  padding: 40px 20px 33px 30px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio_settings.opened {
  right: 0px;
}
.portfolio_settings .wrapper {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_settings .icon {
  position: absolute;
  top: 0px;
  right: 100%;
  background-color: #000;
  padding: 12px 12px 4px 12px;
}
.portfolio_settings .icon .svg {
  color: #fff;
  width: 25px;
  height: 25px;
  animation: fa-spin 2s infinite linear;
}
.portfolio_settings .link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.portfolio_settings .title {
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  position: relative;
  display: inline-block;
}
.portfolio_settings .title:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  left: 0px;
  bottom: 0px;
}
.portfolio_settings .colors {
  margin: 0px 0px 22px 0px;
  list-style-type: none;
  padding-top: 32px;
}
.portfolio_settings .colors li {
  margin: 0px;
  display: inline-block;
}
.portfolio_settings .colors li a {
  text-decoration: none;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.portfolio_settings .direction {
  list-style-type: none;
  margin: 0px;
}
.portfolio_settings .direction li {
  margin: 0px 20px 0px 0px;
  display: inline-block;
}
.portfolio_settings .direction li a {
  opacity: 0.4;
  text-decoration: none;
  color: #fff;
}
.portfolio_settings .direction li a.active {
  opacity: 1;
}
.portfolio_settings .direction li .svg {
  width: 25px;
  height: 25px;
}
.portfolio_settings .colors li.bl {
  display: inline-block;
}
.portfolio_settings .colors li.wh {
  display: none;
}
body.dark .portfolio_settings .colors li.bl,
.portfolio_settings.changed .colors li.bl {
  display: none;
}
body.dark .portfolio_settings .colors li.wh,
.portfolio_settings.changed .colors li.wh {
  display: inline-block;
}
body.dark .portfolio_settings,
.portfolio_settings.changed {
  background-color: #fff;
}
body.dark .portfolio_settings .icon,
.portfolio_settings.changed .icon {
  background-color: #fff;
}
body.dark .portfolio_settings .icon .svg,
.portfolio_settings.changed .icon .svg {
  color: #000;
}
body.dark .portfolio_settings .title,
.portfolio_settings.changed .title {
  color: #000;
}
body.dark .portfolio_settings .title:before,
.portfolio_settings.changed .title:before {
  background-color: rgba(0, 0, 0, 0.5);
}
body.dark .portfolio_settings .direction li a,
.portfolio_settings.changed .direction li a {
  color: #000;
}
body.dark .portfolio_settings .cursor li:nth-child(1) a,
.portfolio_settings.changed .cursor li:nth-child(1) a {
  border-color: #000;
}
body.dark .portfolio_settings .cursor li:nth-child(1) a:before,
.portfolio_settings.changed .cursor li:nth-child(1) a:before {
  background-color: #000;
}
body.dark .portfolio_settings .cursor li .svg,
.portfolio_settings.changed .cursor li .svg {
  color: #000;
}
.portfolio_settings .cursor {
  margin: 0px;
  list-style-type: none;
  padding-top: 15px;
}
.portfolio_settings .cursor li {
  margin: 0px 20px 0px 0px;
  display: inline-block;
}
.portfolio_settings .cursor li a {
  text-decoration: none;
  color: #fff;
  opacity: 0.4;
  font-weight: 600;
}
.portfolio_settings .cursor li a.showme {
  opacity: 1;
}
.portfolio_settings .cursor li:nth-child(1) a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #fff;
  position: relative;
  display: inline-block;
}
.portfolio_settings .cursor li:nth-child(1) a:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.portfolio_settings .cursor li .svg {
  width: 25px;
  height: 25px;
  color: #fff;
}
.portfolio_settings .cursor li:nth-child(2) {
  position: relative;
  top: -2px;
}
.portfolio_settings .effect {
  margin: 0px;
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 25px;
}
.portfolio_settings .effect li {
  margin: 0px 0px 5px 0px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  background-color: #fff;
  opacity: 0.4;
}
.portfolio_settings .effect li a {
  text-decoration: none;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  font-family: "Montserrat";
  display: block;
  position: relative;
  top: 2px;
}
.portfolio_settings .effect li.selected {
  background-color: #fff;
  opacity: 1;
}

/*---------------------------------------------------*/
/*	PORTFOLIO INTRO
/*---------------------------------------------------*/

.portfolio_intro {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_intro .hero {
  width: 100%;
  height: 100vh;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media screen and (max-width: 767px) {
    height: 65vh;
  }
}
.portfolio_intro .hero:before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: repeat;
  opacity: 1;
  z-index: 2;
}
.portfolio_intro .hero:after {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.portfolio_intro .hero .content {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 250px;
  }
}
.x0.x1.x2 {
  margin: 5px 0;
  display: block;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
.portfolio_intro .hero h3 {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 35px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.portfolio_intro .hero p {
  font-family: "Poppins";
  font-weight: 500;
  color: #fff;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 40px;
}
.purchase_button a {
  text-decoration: none;
  color: #000;
  background-color: #fff;
  display: inline-block;
  padding: 13px 48px;
  border: 2px solid #fff;
  font-family: "Poppins";
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.purchase_button a:hover {
  background-color: transparent;
  color: #fff;
}
.portfolio_intro .intro_title {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: center;
  padding: 100px 0px 70px 0px;
  @media screen and (max-width: 767px) {
    padding-bottom: 60px;
  }
}
.portfolio_intro .intro_title h3 {
  font-weight: 800;
  font-size: 40px;
}
.portfolio_intro .demo_list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 60px;
}
.portfolio_intro .demo_list .inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.portfolio_intro .demo_list ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.portfolio_intro .demo_list ul li {
  margin: 0px 0px 20px 0px;
  float: left;
  width: 50%;
  padding-left: 50px;
}
.portfolio_intro .demo_list ul li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  top: 0px;
}
.portfolio_intro .demo_list ul li .list_inner img {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.portfolio_intro .demo_list ul li .list_inner:hover {
  top: -10px;
}
.portfolio_intro .demo_list ul li .list_inner h3 {
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0px;
}

// preview page css
.section-separaton {
  width: 100%;
  background: #191919;
  float: left;
  padding-bottom: 70px;
}

.portfolio_feature {
  .container {
    &.fluid {
      max-width: 100%;
      padding: 0 135px;
      @media screen and (min-width: 280px) and (max-width: 1919px) {
        padding: 0 15px;
      }
    }
  }
  .intro_title h3 {
    color: #fff;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    @media screen and (max-width: 1199px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    @media screen and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .single-features {
    height: 100%;
    padding: 30px 40px;
    border-radius: 5px;
    transition: 0.3s;
    text-align: center;

    img {
      width: 55px;
      height: 55px;
      margin-bottom: 30px;
      background: hsla(0, 0%, 100%, 0.1);
      padding: 10px;
      border-radius: 50%;
      transition: 0.5s;
    }

    &:hover {
      transform: skewY(3deg);
      box-shadow: 0px 2px 30px 0px rgba(213, 55, 102, 0.05);
      img {
        background: #fff;
      }
    }

    h4 {
      font-size: 22px;
      line-height: 1.2;
      color: #fff;
      font-weight: 600;
      margin-bottom: 18px;
    }
    p {
      color: rgba(198, 201, 216, 0.75);
      font-size: 18px;
    }
  }
}

.shane_purchase_banner {
  padding: 150px 0;
  text-align: center;
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  @media screen and (max-width: 767px) {
    padding: 100px 0;
  }

  h2 {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 30px;
  }

  .portfolio_button {
    float: none;
  }
}

/*---------------------------------------------------*/
/*	16) PORTFOLIO MEDIA QUERIES (FOR SMALL DEVIVES)
/*---------------------------------------------------*/
@media (max-width: 1040px) {
  .portfolio_topbar {
    display: none;
  }
  .portfolio_mobile_menu {
    display: block;
  }
  .portfolio_portfolio_titles {
    display: none;
  }
  .portfolio_portfolio .mobile_title {
    display: block;
  }
  .portfolio_hero .content h3 {
    font-size: 35px;
  }
  .portfolio_about .about_inner {
    flex-direction: column;
  }
  .portfolio_about .left {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 50px;
  }
  .portfolio_about .right {
    padding-left: 0px;
    width: 100%;
  }
  .portfolio_portfolio .portfolio_list ul li {
    width: 50%;
  }
  .portfolio_title .title_flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .portfolio_title .portfolio_filter {
    padding-top: 78px;
  }
  .portfolio_portfolio .portfolio_list {
    padding-top: 40px;
  }
  .portfolio_skills .skills_inner {
    flex-direction: column;
  }
  .portfolio_skills .left {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
  .portfolio_skills .right {
    padding-left: 0px;
    width: 100%;
  }
  .portfolio_testimonials .testimonials_inner {
    flex-direction: column;
  }
  .portfolio_testimonials .left {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 50px;
  }
  .portfolio_testimonials .right {
    padding-left: 0px;
    width: 100%;
  }
  .portfolio_news .news_list ul li {
    width: 50%;
  }
  .portfolio_talk .text h3 {
    font-size: 50px;
    line-height: 1.2;
  }
  .portfolio_modalbox_news .description_wrap {
    padding: 50px 20px;
  }
  .portfolio_mobalbox_contact .description_wrap {
    padding: 50px 20px;
  }
  .portfolio_mobalbox_contact .wrapper {
    flex-direction: column;
  }
  .portfolio_mobalbox_contact .left {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 50px;
  }
  .portfolio_mobalbox_contact .right {
    width: 100%;
    padding-left: 0px;
  }
  .portfolio_mobalbox_contact .short_info ul {
    margin: 0px;
  }
  .portfolio_mobalbox_contact .short_info ul li {
    width: 100%;
    padding-left: 0px;
  }
  .portfolio_hero[data-style="one"] .background {
    -webkit-clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);
  }

  .portfolio_hero .image {
    background-position: 51% 100%;
  }
}
@media (max-width: 767px) {
  .portfolio_hero .content {
    padding-right: 0px;
    width: 100%;
    z-index: 5;
    h3 {
      color: #fff;
    }
    .job {
      color: #dedede;
      margin-top: 5px;
    }
  }
  .portfolio_hero {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }
  }
  .portfolio_hero .background {
    width: 100%;
  }

  .portfolio_portfolio .portfolio_list ul {
    margin: 0px;
  }
  .portfolio_portfolio .portfolio_list ul li {
    padding: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
  .portfolio_news .news_list ul {
    margin: 0px;
  }
  .portfolio_news .news_list ul li {
    padding: 0px;
    width: 100%;
  }
  .portfolio_portfolio .portfolio_title {
    margin-bottom: 30px;
  }
  .portfolio_intro .demo_list ul {
    margin: 0px;
  }
  .portfolio_intro .demo_list ul li {
    width: 100%;
    padding-left: 0px;
  }
}

body.intro #preloader:before,
body.intro #preloader:after {
  background-color: #fff;
}
body.intro .loader_line:before {
  background-color: #000;
}
body.intro .loader_line:after {
  background-color: #a8a8a8;
}

.modal-video-close-btn {
  cursor: pointer;
}

.bg_image_props {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.scroll_up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #fff;
  color: #000;
  width: 45px;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0, 0, 0.1, 1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  opacity: 0; // Initially hidden

  img {
    width: 16px;
  }

  &:hover {
    transform: translateY(-5px);
  }

  &.visible {
    opacity: 1; // Make it visible
    animation: fadeUp 0.5s ease-in-out;
  }
  &.hidden {
    opacity: 0; // Make it visible
    z-index: -1;
    animation: fadeUpOut 0.5s ease-in-out;
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeUpOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

// portfolio animation
.portfolio_list .react-tabs__tab-panel--selected {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running customOne;
  animation: 800ms ease-in-out 0s normal none 1 running customOne;
}

@keyframes customOne {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}

.social-menu {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.social-menu .share {
  display: flex;
  align-items: center;
  span {
    display: none;
  }
}
.social-menu .share .social {
  flex-direction: row !important;
  li {
    margin: 0 10px !important;
  }
  img {
    filter: invert(1);
    width: 20px;
    height: 20px;
  }
}

.slider-social {
  list-style: none;
  display: flex;
  align-items: center;
}

// home version three
.home-three {
  .portfolio_topbar {
    position: sticky;
    background: #fff;
    border-bottom: 1px solid #efefef;
  }
  .portfolio_hero {
    height: calc(100vh - 80px);
    background: #f9f9f9;
    @media screen and (max-width: 1040px) {
      height: calc(60vh - 80px);
    }
  }
}
.position-relative {
  position: relative;
  overflow: hidden;
}
.slider-four {
  .white-fill-bg {
    margin-top: 35px;
    position: relative;
    overflow: hidden;
  }
}
.btn-medium {
  padding: 12px 41px;
  @media screen and (max-width: 768px) {
    padding: 8px 25px;
  }
}
.portfolio_topbar .white-fill-bg.btn-outline {
  margin-left: 10px;
}
.modal-video-close-btn {
  @media screen and (min-width: 1041px) {
    top: 0 !important;
    right: -40px !important;
  }
}
.home-four {
  .portfolio_hero .background {
    width: 100% !important;
  }
  .portfolio_hero .image {
    background-position: 100% 12%;
  }
  @media (max-width: 1040px) {
    .portfolio_hero .image {
      background-position: 71% 100%;
    }
  }

  .portfolio_hero[data-style="one"] .background {
    -webkit-clip-path: none !important;
    clip-path: none !important;
  }
  .portfolio_hero .content h3 span {
    -webkit-text-stroke: 1px #fff;
  }
  .portfolio_hero .content h3 {
    color: #fff;
    position: relative;
    overflow: hidden;
  }
  .portfolio_topbar .topbar_inner .logo a img {
    filter: invert(1);
  }
  .portfolio_topbar .menu ul li a {
    color: #fff;
  }
  .portfolio_topbar .menu ul li.current a {
    color: #efefef;
  }
  .portfolio_topbar .topbar_inner.opened {
    background-color: #000;
  }
  .portfolio_topbar .topbar_inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  .portfolio_hero .content .job {
    font-weight: 700;
    font-size: 35px;
    font-family: "Montserrat";
    color: #fff;
    padding: 8px 0px 10px;
    @media (max-width: 1040px) {
      font-size: 20px;
      font-weight: 600;
      padding: 8px 0 0;
    }
  }
  .portfolio_hero .content {
    z-index: 3;
  }

  .portfolio_hero .content h3 {
    line-height: 1.3;
  }
  @media screen and (max-width: 767px) {
    .portfolio_hero .content h3 {
      font-size: 40px;
    }
    .portfolio_hero .content .job {
      font-size: 25px;
      padding-top: 0;
    }
  }
}
.home-one {
  .portfolio_hero .image {
    background-position: center right;
    @media screen and (max-width: 1040px) {
      background-position: 81% 100%;
    }
  }
  .portfolio_hero {
    background: rgba(253, 253, 253, 1);
  }
  .white-fill-bg.btn_sm {
    border-color: #555 !important;
    &:hover {
      border-color: #fff !important;
    }
  }
}
.home-one,
.home-two,
.home-three,
.home-three {
  .white-fill-bg.btn_sm {
    border-color: #555 !important;
    &:hover {
      border-color: #000 !important;
      background: #000 !important;
      color: #fff !important;
    }
  }
}

.slider-two {
  .social.social-default li img.svg {
    filter: invert(0.3);
    @media screen and (max-width: 767px) {
      filter: invert(1);
      width: 18px;
      height: 18px;
    }
    &:hover {
      filter: invert(0);
      @media screen and (max-width: 767px) {
        filter: invert(0.9);
      }
    }
  }
}
// particle css
.frame-layout__particles {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
  > div {
    width: 100%;
    height: 100%;
  }
}

// loop text
.loop-text {
  color: #fff !important;
  text-transform: capitalize;
  font-weight: 800;
  @media screen and (max-width: 1040px) {
    font-weight: 700;
    font-size: 32px;
  }
}

.social-default {
  margin-top: 14px;
  height: 40px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
  li {
    align-items: center;
    position: relative;
  }
  .svg {
    transition: 0.3s;
    &:hover {
      transform: translateY(-5px);
    }
  }
}

.shane_error_page {
  h1,
  h2 {
    font-weight: 900;
    font-size: 125px;
    font-family: "Montserrat";
    line-height: 1;
    letter-spacing: 5px;
    @media screen and (max-width: 768px) {
      font-size: 80px;
    }
  }
  &.shane_error_page {
    background: transparent;
  }
  &.portfolio_intro .hero {
    &::after {
      display: none;
    }
    p {
      color: #000;
      padding-bottom: 45px;
      @media screen and (max-width: 768px) {
        padding-bottom: 30px;
        font-size: 18px;
        padding-top: 20px;
      }
    }
    .white-fill-bg {
      border-color: #000 !important;
      color: #000 !important;
      font-weight: 500;
      &:hover {
        background: #000 !important;
        color: #fff !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .prev-logo {
    height: 45px;
  }
  .logo {
    img {
      max-width: 80px;
    }
  }
}
