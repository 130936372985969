.about__profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 75%;
    border-radius: 100%;
    margin: 0 auto;
  }

  h2 {
    padding-top: 2rem;
    font-weight: 800;
    font-family: "Poppins";
  }
  h3 {
    padding-top: 0.5rem;
    font-weight: 700;
    font-family: "Poppins";
  }
  @media screen and (max-width: 546px) {
    img {
      width: 90%;
    }
    h2 {
      padding-top: 1.5rem;
      font-size: 2rem;
    }
    h3 {
      font-size: 1.4rem;
    }
  }
}
.portfolio_section .portfolio_about .container .about_inner {
  @media screen and (max-width: 546px) {
    .left {
      margin-bottom: 10px;
    }
    .right {
      margin-bottom: 10px;
    }
  }
}
#aboutPage_1 {
  margin-top: 40px;
  @media screen and (max-width: 1040px) {
    margin-top: 0;
  }
}
#aboutPage_2 .portfolio_about .container .about_inner {
  @media screen and (max-width: 546px) {
    flex-direction: column-reverse;
  }
}
.about_languages {
  h3 {
    padding: 20px 0;
  }
  h5 {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    svg {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }
  clear: both;
}
.about_tecnologies {
  h3 {
    padding: 20px 0;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      padding-right: 20px;
      display: flex;
      align-items: center;
      color: #222;
      width: 33%;
      svg {
        color: #222;
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }
    }
  }
}
.experience_modal {
  padding: 2.5rem;
  max-height: 80vh;
  overflow-y: scroll;
  @media (max-width: 599px) {
    padding: 1rem;
  }
  .experience_modal_content {
    @media (max-width: 1600px) {
      width: 1070px;
    }
    @media (max-width: 1400px) {
      width: 900px;
    }
    @media (max-width: 992px) {
      width: 700px;
    }
    @media (max-width: 768px) {
      width: 560px;
    }
    @media (max-width: 599px) {
      width: 460px;
    }
    @media (max-width: 490px) {
      width: 360px;
    }
    @media (max-width: 420px) {
      width: 320px;
    }
  }
}
.portfolio_about_experience {
  width: 100%;
  display: flex;
  padding-bottom: 20px;

  .experience_left {
    width: 25%;
    h6 {
    }
    a {
      color: inherit;
      text-decoration: inherit;

      transition: font-weight 0.2s ease;
      &:hover {
        font-weight: bold;
      }
    }
    .separator {
      display: flex;
      align-items: center;
      svg {
        color: white;
        background-color: black;
        border-radius: 100%;
        height: 18px;
        width: 18px;
      }

      span {
        display: block;
        border: 0.2px solid #444;
        flex-grow: 1;
      }
    }
  }
  .experience_right {
    width: 75%;
    padding: 15px 0 0 25px;
    button {
      text-decoration: none;
      padding: 4px 15px;
      display: inline-block;
      border: none;
      font-family: "Poppins";
      cursor: pointer;
      font-size: 12px;
      background: #262626;
      color: #fff;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
.about_subtitle {
  padding: 20px 0;
}
.portfolio_button_about {
  text-align: center;
  padding-top: 50px;
}
#aboutPage_1 .portfolio_about {
  background-color: #fff;
  padding-bottom: 25px;
}
#aboutPage_2 .portfolio_about {
  // background-color: #fff;
  padding-top: 25px;
}
#aboutPage_3 .portfolio_about {
  padding: 50px 0;
  background-size: cover;
  .title h3 {
    text-align: center;
    font-size: 40px;
    padding-bottom: 20px;
  }
}

// ___________PROJECTS SECTIOn______________________

.projects__section {
  .projects__section_title h3 {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    margin-top: 80px;
    font-weight: 800;
    font-family: "Poppins";
    line-height: 1.4;
    font-size: 35px;
    @media screen and (max-width: 786px) {
      font-size: 30px;
      padding-bottom: 10px;
    }
  }
  .projects__section_projects {
    display: flex;
    justify-content: center;
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 50px 0;
      @media screen and (max-width: 546px) {
        padding: 20px 0;
      }
    }
  }
}

.projectItem {
  @media screen and (max-width: 546px) {
    width: 100%;
    padding: 0 5px;
  }
  @media screen and (max-width: 1040px) {
    width: 50%;
    padding: 0 10px;
  }
  width: 33.3%;
  min-width: 340px;
  max-width: 400px;
  height: 520px;
  padding: 0 15px;
  margin-bottom: 30px;
  .projectItem_image {
    min-width: 100%;
    img {
      height: 250px;
      object-fit: cover;
    }
  }
  .projectItem_description {
    padding: 0 15px;
    height: 270px;
    position: relative;
    border: 1px solid black;
    h3 {
      padding: 10px 0;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      max-height: 155px;
      overflow-y: hidden;
      line-height: 1.2;
    }
    .projectItem_buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 5px 15px;
      a {
        text-decoration: none;
        color: #fff;
        background-color: #000;
        display: flex;
        align-items: center;
        padding: 8px 24px;
        border: 2px solid #000;
        font-family: "Poppins";
        transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
        svg {
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
        &:hover {
          color: #000;
          background-color: transparent;
          transform: translateY(-3px);
        }
      }
    }
  }
}
//____________

.logo a img {
  max-height: 100px;
}
